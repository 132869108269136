import React from "react";
import logo from "../assets/logo.svg";
import Loader from "./Loader";
import Deso from "deso-protocol";
import "../../src/App.css";
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link } from "react-router-dom";
import DesoApi from "../tools/desoAPI";
const deso = new Deso();
const da = new DesoApi();
const firstPostHashHex =
  "940526a0cd3519d444ba09a05ba34123c78f3c69496f1a2d0a26c562fa253dc7";
const slotNFTHash =
  "83af3b9d584ee0d1301c8d6bee1b373aa609b35de49bbc5d6178f5d46ad2da42";

const slotNFTHash2 = "8b547191eda2e742b29f60d8c7b53c02770958ea35127d996d77cae1f16b5b3c"
const listOfPostHashToIgnore = [
  "daaa73f6f506895deaec8aecc5bc1b3acbfc6171d01adafac89fa28b5bdb8941",
  "67395c11742e61128166ed5fe2541aa4098ebc03eb94acec253b14816e55e03d",
  "da8e367c999181ddfa4d7671e6220997921851d1994935cba5c2f5a91384081c"
];
export default function Landing() {
  const [isLogging, setIsLogging] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(true);
  const [lastPostHashHex, setLastPostHashHex] = useState("");
  const [posts, setPosts] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [slotNftInfo, setSlotNftInfo] = useState(null);
  const [slotNft2Info, setSlotNft2Info] = useState(null);
  const [uniqueOwnerMap, setUniqueOwnerMap] = useState(null);
  const [useHasNFT, setUseHasNFT] = useState(false);

  
  useEffect(() => {
    async function initLoginData() {
      // wait for 3 seconds

      const loggedInPublicKey = localStorage.getItem("loggedInUser");
      console.log(loggedInPublicKey);
      const request = {
        PostHashHex: slotNFTHash,
      };
      const response2 = await deso.nft.getNftEntriesForPostHash(request);
      const slotNftInfoList = response2.data.NFTEntryResponses;
      setSlotNftInfo(slotNftInfo);

      const responseOfSlotNft2 = await deso.nft.getNftEntriesForPostHash({
        PostHashHex: slotNFTHash2,
      })

      const slotNft2InfoList = responseOfSlotNft2.data.NFTEntryResponses;
      setSlotNft2Info(slotNft2InfoList);

      //get unique owner of slotNFT
      let ownerMap = {};
      slotNftInfoList.forEach((slotNftInfo) => {
        if (slotNftInfo.OwnerPublicKeyBase58Check in ownerMap) {
          ownerMap[slotNftInfo.OwnerPublicKeyBase58Check] += 1;
        } else {
          ownerMap[slotNftInfo.OwnerPublicKeyBase58Check] = 1;
        }
      });
      slotNft2InfoList.forEach((slotNftInfo) => {
        if (slotNftInfo.OwnerPublicKeyBase58Check in ownerMap) {
          ownerMap[slotNftInfo.OwnerPublicKeyBase58Check] += 1;
        } else {
          ownerMap[slotNftInfo.OwnerPublicKeyBase58Check] = 1;
        }
      }
      );
      setUniqueOwnerMap(ownerMap);

      if (loggedInPublicKey) {
        //const loggedInUser = await deso.getUser(loggedInPublicKey);
        setLoggedInUser(loggedInPublicKey.toString());
        if (loggedInPublicKey in ownerMap) {
          setUseHasNFT(true);
        }
      }

      setIsPageLoading(false);
    }

    initLoginData();
  }, []);

  function getTaggedUser(bodyPost) {
    //replace '/n' with ' ' in bodyPost
    const body = bodyPost.replace(/\n/g, " ");

    const taggedUser = body.match(/@[a-zA-Z0-9]+/g);

    return taggedUser;
  }

  useEffect(() => {
    async function getLatestNFTs() {
      const latestNFTs = await da.getAiManiaPosts();
      const allPost = latestNFTs.Posts;
      //remove that value in array where PostHashHex is equal to slotNFTHash
      const filteredPosts = allPost.filter(
        (post) => post.PostHashHex !== slotNFTHash
      );
      console.log(filteredPosts);
      //ermove that value in array where IsNFT is false
      const filteredPosts3 = filteredPosts.filter(
        (post) => post.IsNFT != false
      );

      const filteredPosts2 = filteredPosts3.filter(
        (post) => post.IsHidden != true
      );

      setPosts(filteredPosts2);
      //console.log(filteredPosts2);
      setLastPostHashHex(latestNFTs.LastPostHashHex);

      setLoadingPosts(false);
    }
    getLatestNFTs();
  }, []);
  const fetchMoreData = async () => {
    try {
      const latestPosts = await da.getAiManiaPosts(lastPostHashHex);
      const allPost = latestPosts.Posts;
      const filteredPosts = allPost.filter(
        (post) => post.PostHashHex !== slotNFTHash
      );
      //ermove that value in array where IsNFT is false
      const filteredPosts3 = filteredPosts.filter(
        (post) => post.IsNFT != false
      );
      const filteredPosts2 = filteredPosts3.filter(
        (post) => post.IsHidden != true
      );
      //remove that value in array where PostHasHex is present in listOfPostHashToIgnore

      const filteredPosts4 = filteredPosts2.filter(
        (post) => !listOfPostHashToIgnore.includes(post.PostHashHex)
      );

      setPosts([...posts, ...filteredPosts4]);
      setLastPostHashHex(latestPosts["LastPostHashHex"]);
      if (latestPosts["LastPostHashHex"] === firstPostHashHex) {
        setHasMore(false);
      }
    } catch (e) {
      //console.log(e);
    }
  };

  const handleLogin = async () => {
    setIsLogging(true);
    const user = await deso.identity.login(2);
    console.log(user);
    localStorage.setItem("loggedInUser", user.key);
    if (user.key in uniqueOwnerMap) {
      setUseHasNFT(true);
    }
    setLoggedInUser(user.key);
    setIsLogging(false);
  };

  const handleLogOut = async () => {
    if (isLoggingOut) {
      return;
    }
    setIsLoggingOut(true);
    //clear local storage

    localStorage.removeItem("loggedInUser");

    setIsLoggingOut(false);
    //reload page
    window.location.reload();
  };
  return (
    <div>
      {isPageLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`gradient leading-relaxed tracking-wide flex flex-col`}>
            <nav id='header' className='w-full z-30 top-0  py-1 lg:py-6'>
              <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-2 lg:py-6'>
                <div className='flex items-center'>
                  <a
                    to='/'
                    className='no-underline flex hover:no-underline font-bold text-2xl lg:text-4xl'>
                    <img
                      src={logo}
                      alt='logo'
                      className='h-16 w-16 rounded-full'
                    />
                    <span className='mx-2 my-2 text-white'> Noun141 </span>
                  </a>
                </div>

                <div className='' id='nav-content'>
                  {isLogging ? (
                    <div className='mx-auto px-10'>
                      <Loader />
                    </div>
                  ) : loggedInUser !== null ? (
                    <div className='mx-auto px-10 flex flex-col items-center'>
                      <button>
                        <img
                          className='h-12 w-12 rounded-full'
                          src={`https://node.deso.org/api/v0/get-single-profile-picture/${loggedInUser}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                        />
                      </button>
                      {/*  add a click to log out drop down buttton*/}
                      <div className='flex flex-col items-center justify-center my-1'>
                        <button
                          className='bg-white text-black font-bold py-2 px-4 rounded-full hover:bg-slate-200'
                          onClick={() => {
                            handleLogOut();
                          }}>
                          Logout
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={handleLogin}
                      target={"_blank"}
                      id='navAction'
                      className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-800 font-extrabold rounded mt-4 lg:mt-0 py-4 px-8 shadow '>
                      Login
                    </button>
                  )}
                </div>
              </div>
            </nav>

            <div className='container mx-auto py-4'>
              <div className='text-center px-3 lg:px-0'>
                <h1 className='my-4 text-3xl md:text-3xl lg:text-6xl font-black leading-tight text-white'>
                Create an image worth 1000 words in less than 10 words
                </h1>
                <p className='leading-normal  my-10 text-base md:text-xl lg:text-2xl mb-8 text-white'>
                Noun141 is AI based user generated NFT series on DeSo
                  Blockchain
                </p>
                {!useHasNFT && loggedInUser==null ? (
                  <p className='leading-normal  my-10 text-base md:text-lg lg:text-xl mb-8 text-white'>
                    Do you have Input slots? Grab {" "}
                    <span>
                      <a
                        href='https://diamondapp.com/nft/83af3b9d584ee0d1301c8d6bee1b373aa609b35de49bbc5d6178f5d46ad2da42'
                        target={"_blank"}
                        className='underline text-blue-300'>
                        Type1 NFT Slot
                      </a>
                    </span>
                    &nbsp; or &nbsp;
                    <span>
                      <a
                        href='https://diamondapp.com/nft/8b547191eda2e742b29f60d8c7b53c02770958ea35127d996d77cae1f16b5b3c'
                        target={"_blank"}
                        className='underline text-blue-300'>
                        Type2 NFT Slot
                      </a>
                    </span>

                  </p>
                ) : null}
                {useHasNFT ? (
                  <>
                    <p className='leading-normal  mt-10 text-2xl mb-10 text-white'>
                      You have Input Slot NFTs!
                    </p>
                    <Link
                      className='gradient2 px-5 py-3 my-4 underline text-xl rounded-md font-bold'
                      to='/mint'>
                      Mint NFTs
                    </Link>
                  </>
                ) : !useHasNFT && loggedInUser != null?(
                  <p className='leading-normal  mt-10 text-2xl mb-10 text-white'>
                    You don't have Input Slot NFTs! Grab some from{" "}
                    <span>
                      <a
                        href='https://diamondapp.com/nft/83af3b9d584ee0d1301c8d6bee1b373aa609b35de49bbc5d6178f5d46ad2da42'
                        target={"_blank"}
                        className='underline text-blue-300'>
                        here
                      </a>
                    </span>
                  </p>
                ):null}

                {loggedInUser == null ? (
                  <button
                    className=' buttonGold mx-auto lg:mx-0 hover:underline text-gray-800 font-extrabold rounded my-2 md:my-6 py-4 px-8 shadow-lg w-70 hover:shadow-xl'
                    onClick={handleLogin}>
                    Verify Input Slot NFT
                  </button>
                ) : null}
              </div>
            </div>
          </div>

          <section className='border-b py-8'>
            <div className='container mx-auto flex flex-wrap pt-4 pb-12'>
              <h2 className='w-full my-2 text-5xl font-black leading-tight text-center text-gray-800'>
                Latest Mints
              </h2>
            </div>

            {loadingPosts ? (
              <div className='flex justify-center'>
                <Loader />
              </div>
            ) : (
              <div className='container mx-auto flex flex-wrap'>
                <InfiniteScroll
                  className='container mx-auto flex flex-wrap pt-4 pb-12'
                  dataLength={posts.length}
                  next={fetchMoreData}
                  hasMore={hasMore}
                  loader={
                    <div className='my-3 flex justify-center mx-auto'>
                      <Loader />
                    </div>
                  }>
                  {posts.map((post, index) => {
                    return (
                      <div
                        className='w-full md:w-1/2 lg:w-1/3 p-4 hover:scale-105 duration-100'
                        key={index}>
                        <div className=' bg-white rounded-lg shadow-xl border-t-black'>
                          <Link
                            to={`/NFT/${post.PostHashHex}`}
                            className='no-underline flex hover:no-underline font-bold text-2xl lg:text-4xl'>
                            <img
                              src={post.ImageURLs[0]}
                              className='rounded-t-lg'></img>
                          </Link>
                          <div className='py-5 flex justify-center'>
                            <p className='my-3 text-lg'>
                              Minted by{" "}
                              <span>
                                <a
                                  className='underline text-blue-400'
                                  href={`https://diamondapp.com/u/${getTaggedUser(
                                    post.Body
                                  )
                                    .toString()
                                    .slice(1)}`}>
                                  {getTaggedUser(post.Body)}
                                </a>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </InfiniteScroll>
              </div>
            )}
          </section>
        </>
      )}
    </div>
  );
}
