import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Create from "./Components/Create";
import Landing from "./Components/Landing";
import MintPage from "./Components/MintPage";
import NFT from "./Components/NFT";

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<Landing />} />
        <Route path='/mint' element={<MintPage />} />
        <Route path='/create' element={<Create/>} />
        <Route path='NFT/:postHashHex' element={<NFT/>} />
      </Routes>
    </Router>
  );
}

export default App;
