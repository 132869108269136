import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Deso from "deso-protocol";
import logo from "../assets/logo.svg";
import Loader from "./Loader";
import DesoApi from "../tools/desoAPI";
const deso = new Deso();
const da = new DesoApi();
const slotNFTHash =
  "83af3b9d584ee0d1301c8d6bee1b373aa609b35de49bbc5d6178f5d46ad2da42";
export default function MintPage() {
  const [slotNftInfo, setSlotNftInfo] = useState(null);
  const [uniqueOwnerMap, setUniqueOwnerMap] = useState(null);
  const [useHasNFT, setUseHasNFT] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [success, setSuccess] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loggedInUser, setLoggedInUser] = useState(null);
  useEffect(() => {
    async function initLoginData() {
      // wait for 3 seconds

      const loggedInPublicKey = localStorage.getItem("loggedInUser");
      console.log(loggedInPublicKey);
      const request = {
        PostHashHex: slotNFTHash,
      };
      const response2 = await deso.nft.getNftEntriesForPostHash(request);
      const slotNftInfoList = response2.data.NFTEntryResponses;
      setSlotNftInfo(slotNftInfo);

      //get unique owner of slotNFT
      let ownerMap = {};
      slotNftInfoList.forEach((slotNftInfo) => {
        if (slotNftInfo.OwnerPublicKeyBase58Check in ownerMap) {
          ownerMap[slotNftInfo.OwnerPublicKeyBase58Check] += 1;
        } else {
          ownerMap[slotNftInfo.OwnerPublicKeyBase58Check] = 1;
        }
      });
      setUniqueOwnerMap(ownerMap);

      if (loggedInPublicKey) {
        //const loggedInUser = await deso.getUser(loggedInPublicKey);
        setLoggedInUser(loggedInPublicKey.toString());
        if (loggedInPublicKey in ownerMap) {
          setUseHasNFT(true);
        } else {
          window.location.href = "/";
        }
      } else {
        window.location.href = "/";
      }

      setIsPageLoading(false);
    }

    initLoginData();
  }, []);
  const handleMintRequest = async () => {
    if (isSending) {
      return;
    }
    setIsSending(true);
    const inputValue = document.getElementById("inputValue").value;
    const jwtValue = await deso.identity.getJwt(undefined);
    if (inputValue === "") {
      alert("Please enter a value");
      setIsSending(false);
      return;
    }
    //make a POST request to mint NFT
    const mintResponse = await da.mintNewNFT(
      inputValue,
      jwtValue,
      loggedInUser
    );
    if (mintResponse) {
      if (mintResponse.status) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    }
    setIsSending(false);
  };
  return (
    <div>
      {isPageLoading ? (
        <Loader />
      ) : (
        <>
          <div
            className={`gradient leading-relaxed tracking-wide flex flex-col`}>
            <nav id='header' className='w-full z-30 top-0  py-1 '>
              <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-2 lg:py-6'>
                <div className='flex items-center'>
                  <Link
                    to='/'
                    className='no-underline flex hover:no-underline font-bold text-2xl lg:text-4xl'>
                    <img
                      src={logo}
                      alt='logo'
                      className='h-16 w-16 rounded-full'
                    />
                    <span className='mx-2 text-white'> Noun141 </span>
                  </Link>
                </div>

                <div className='' id='nav-content'></div>
              </div>
            </nav>
          </div>
          <div
            className='bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4'
            role='alert'>
            <p className='font-bold'>Important!</p>
            <p>
              Make sure you perfectly describe the image and not just leave
              random quote or something. Image description will be reviewed
              before minting. NSFW or disturbing inputs are not allowed. For good quality renders, add keywords like "3d render", "digital painting" etc
            </p>
          </div>
          <div className='my-24 px-10'>
            <div className=''>
              <input
                type='text'
                id='inputValue'
                placeholder='Enter your detailed image description'
                className='py-2 px-4 w-full bg-gray-100 rounded-lg border-2 border-gray-200 focus:outline-none focus:border-gray-400'
              />
            </div>
            <div className='flex justify-center my-8'>
              <button
                className='gradient2 px-12 py-3 font-bold underline rounded-md'
                onClick={handleMintRequest}>
                {isSending ? "Sending..." : "Mint"}
              </button>
            </div>
            {success ? (
              <div
                className='bg-blue-100 border rounded-md border-blue-500 text-blue-700 mx-10 px-4 py-3'
                role='alert'>
                <p className='font-bold'>Request Sent Successfully</p>
                <p className='text-sm'>
                  Your image is being reviewed before minting
                </p>
              </div>
            ) : !success && success != null ? (
              <div
                className='bg-red-100 border rounded-md border-red-500 text-red-700 mx-10 px-4 py-3'
                role='alert'>
                <p className='font-bold'>Something went wrong!</p>
                <p className='text-sm'>Please give it another shot.</p>
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
}
