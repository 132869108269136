import { React, useState, useEffect } from "react";
import logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import Deso from "deso-protocol";
import Loader from "./Loader";
const deso = new Deso();
export default function Create() {
  const [loading, setLoading] = useState(true);
  const [isOneUploading, setIsOneUploading] = useState(false);
  const [firstImageURL, setFirstImageURL] = useState("");
  const [isTwoUploading, setIsTwoUploading] = useState(false);
  const [secondImageURL, setSecondImageURL] = useState("");
  const [firstUserProfile, setFirstUserProfile] = useState(null);
  const [secondUserProfile, setSecondUserProfile] = useState(null);

  const [nftNumberOne, setNftNumberOne] = useState("");
  const [nftNumberTwo, setNftNumberTwo] = useState("");
  const [inputNumberOne, setInputNumberOne] = useState("");
  const [inputNumberTwo, setInputNumberTwo] = useState("");
  const [secondMintRequesterInput, setSecondMintRequesterInput] = useState("");

  const [promtOneValue, setPromtOneValue] = useState("");
  const [promtTwoValue, setPromtTwoValue] = useState("");

  const [isMintingOne, setIsMintingOne] = useState(false);
  const [isOneMinted, setIsOneMinted] = useState(false);

  const [isMintingTwo, setIsMintingTwo] = useState(false);
  const [isTwoMinted, setIsTwoMinted] = useState(false);

  const [level4loggedPk, setLevel4loggedPk] = useState("");
  const [isLogging, setIsLogging] = useState(false);

  useEffect(() => {
    setLoading(true);
    async function initLoginData() {
      //wait for 3 seconds

      const aiManiaPublicKey = localStorage.getItem("aiManiaPublicKey");
      if (aiManiaPublicKey) {
        setLevel4loggedPk(aiManiaPublicKey);
      }
      setLoading(false);
    }
    initLoginData();
  }, []);

  const handleDesoLogin = async () => {
    if (isLogging) {
      return;
    }
    setIsLogging(true);
    const hasLogged = await deso.identity.login(4);
    if (hasLogged.key) {
      localStorage.setItem("aiManiaPublicKey", hasLogged.key);
      setLevel4loggedPk(hasLogged.key);
    }
    setIsLogging(false);
  };

  const handleDesoLogout = async () => {
    localStorage.removeItem("aiManiaPublicKey");
    window.location.reload();
  };
  const handleImageUpload = async () => {
    if (level4loggedPk === "") {
      alert("Please login to upload images");
      return;
    }
    if (isOneUploading) return;
    setIsOneUploading(true);
    console.log("is uploading");
    const publicKeyOfUser = level4loggedPk;
    const request = {
      UserPublicKeyBase58Check: publicKeyOfUser,
    };

    const response = await deso.media.uploadImage(request);

    if (response) {
      setFirstImageURL(response.ImageURL);
    } else {
      alert("Error uploading image");
    }
    setIsOneUploading(false);

    //insert ImageURL in listOfPost where serial is equal to serial
  };

  const handleImageUpload2 = async () => {
    if (level4loggedPk === "") {
      alert("Please login to upload images");
      return;
    }
    if (isTwoUploading) return;
    setIsTwoUploading(true);
    console.log("is uploading");
    const publicKeyOfUser = level4loggedPk;
    const request = {
      UserPublicKeyBase58Check: publicKeyOfUser,
    };
    const response = await deso.media.uploadImage(request);

    if (response) {
      setSecondImageURL(response.ImageURL);
    } else {
      alert("Error uploading image");
    }
    setIsTwoUploading(false);
  };

  const mintRequesterOneChange = async (e) => {
    if (level4loggedPk === "") {
      alert("Please login to upload images");
      return;
    }
    console.log(e.target.value);
    const requesterOnePublicKey = e.target.value;
    if (requesterOnePublicKey.toString().length == 55) {
      const request = {
        PublicKeyBase58Check: requesterOnePublicKey,
      };
      try {
        const response = await deso.user.getSingleProfile(request);
        if (response) {
          setFirstUserProfile({
            username: response.Profile.Username
              ? response.Profile.Username
              : requesterOnePublicKey,
            publicKey: response.Profile.PublicKeyBase58Check,
          });

          setSecondUserProfile({
            username: response.Profile.Username
              ? response.Profile.Username
              : requesterOnePublicKey,
            publicKey: response.Profile.PublicKeyBase58Check,
          });
          setSecondMintRequesterInput(requesterOnePublicKey);
        }
      } catch (err) {
        alert(
          "User not found. Please make sure you have entered the correct public key that has username"
        );
      }
    } else {
      setFirstUserProfile(null);
      setSecondUserProfile(null);
    }
  };

  const mintRequesterTwoChange = async (e) => {
    setSecondMintRequesterInput(e.target.value);
    console.log(e.target.value);
    const requesterTwoPublicKey = e.target.value;
    if (requesterTwoPublicKey.toString().length == 55) {
      const request = {
        PublicKeyBase58Check: requesterTwoPublicKey,
      };
      const response = await deso.user.getSingleProfile(request);
      if (response) {
        setSecondUserProfile({
          username: response.Profile.Username
            ? response.Profile.Username
            : requesterTwoPublicKey,
          publicKey: response.Profile.PublicKeyBase58Check,
        });
      }
    }
  };

  const handleNftNumberOneChange = (e) => {
    setNftNumberOne(e.target.value);
    setNftNumberTwo((parseInt(e.target.value) + 1).toString());
  };

  const handleInputNumberOnChange = (e) => {
    setInputNumberOne(e.target.value);
    setInputNumberTwo(e.target.value);
  };

  const handlePromtOneChange = (e) => {
    setPromtOneValue(e.target.value);
    setPromtTwoValue(e.target.value);
  };

  const handleMintOne = async () => {
    if (level4loggedPk === "") {
      alert("Please login to upload images");
      return;
    }
    try {
      if (isMintingOne) return;
      if (isOneMinted) {
        alert("You have already minted this NFT");
        return;
      }
      setIsMintingOne(true);
      const nftNum = nftNumberOne;
      const inputNum = inputNumberOne;
      const promt = promtOneValue;
      const imageURL = firstImageURL;
      if (!firstUserProfile) {
        setIsMintingOne(false);
        alert("Please enter a valid public key");
        return;
      }
      const requesterPublicKey = firstUserProfile.publicKey;
      const requesterUsername = firstUserProfile.username;
      if (!imageURL) {
        setIsMintingOne(false);
        alert("Please upload an image");
        return;
      }
      if (!requesterPublicKey) {
        setIsMintingOne(false);
        alert("Please enter a public key");
        return;
      }
      if (
        nftNum.length == 0 ||
        inputNum.length == 0 ||
        requesterPublicKey.length == 0 ||
        promt.length == 0 ||
        imageURL.length == 0 ||
        requesterUsername.length == 0
      ) {
        setIsMintingOne(false);
        alert("Please fill all the fields");
        return;
      }
      const publicKeyOfUser = level4loggedPk;
      const finalBody = `Noun141 #${nftNum}\nInput: ${inputNum}\nBy @${requesterUsername} \n\n\"${promt}"`;
      const request = {
        UpdaterPublicKeyBase58Check: publicKeyOfUser,
        BodyObj: {
          Body: finalBody,
          VideoURLs: [],
          ImageURLs: [imageURL],
        },
      };
      const response = await deso.posts.submitPost(request);
      console.log(response);
      const postHashHexOfNFT =
        response.submittedTransactionResponse.PostEntryResponse.PostHashHex;
      console.log(postHashHexOfNFT);

      //wait for 4 seconds
      await new Promise((resolve) => setTimeout(resolve, 4000));
      //code to mint the nft
      const request2 = {
        UpdaterPublicKeyBase58Check: publicKeyOfUser,
        NFTPostHashHex: postHashHexOfNFT.toString(),
        NumCopies: 1,
        NFTRoyaltyToCreatorBasisPoints: 500,
        NFTRoyaltyToCoinBasisPoints: 0,
        MinBidAmountNanos: 2000000000,
        HasUnlockable: false,
        IsForSale: false,
        MinFeeRateNanosPerKB: 1000,
      };
      const response2 = await deso.nft.createNft(request2);
      await new Promise((resolve) => setTimeout(resolve, 4000));

      //code to transfer the nft
      const request3 = {
        SenderPublicKeyBase58Check: publicKeyOfUser,
        ReceiverPublicKeyBase58Check: requesterPublicKey,
        NFTPostHashHex: postHashHexOfNFT.toString(),
        SerialNumber: 1,
        MinFeeRateNanosPerKB: 1000,
      };
      const response3 = await deso.nft.transferNft(request3);
      alert("NFT MINTED SUCCESSFULLY");
      setIsMintingOne(false);
      setIsOneMinted(true);
    } catch (err) {
      alert(`Error minting NFT: ${err}`);
      setIsMintingOne(false);

      //add reload later on
    }
  };

  const handleMintTwo = async () => {
    if (level4loggedPk === "") {
      alert("Please login to upload images");
      return;
    }
    try {
      if (isMintingTwo) return;
      if (isTwoMinted) {
        alert("You have already minted this NFT");
        return;
      }
      setIsMintingTwo(true);
      const nftNum = nftNumberTwo;
      const inputNum = inputNumberTwo;
      const promt = promtTwoValue;
      const imageURL = secondImageURL;
      if (!secondUserProfile) {
        setIsMintingTwo(false);
        alert("Please enter a valid public key");
        return;
      }
      const requesterPublicKey = secondUserProfile.publicKey;
      const requesterUsername = secondUserProfile.username;
      if (!imageURL) {
        setIsMintingTwo(false);
        alert("Please upload an image");
        return;
      }
      if (!requesterPublicKey) {
        setIsMintingTwo(false);
        alert("Please enter a public key");
        return;
      }
      if (
        nftNum.length == 0 ||
        inputNum.length == 0 ||
        requesterPublicKey.length == 0 ||
        promt.length == 0 ||
        imageURL.length == 0 ||
        requesterUsername.length == 0
      ) {
        setIsMintingTwo(false);
        alert("Please fill all the fields");
        return;
      }
      const publicKeyOfUser = level4loggedPk;
      const finalBody = `Noun141 ${nftNum}\nInput: ${inputNum}\nBy @${requesterUsername} \n\n\"${promt}"`;
      const request = {
        UpdaterPublicKeyBase58Check: publicKeyOfUser,
        BodyObj: {
          Body: finalBody,
          VideoURLs: [],
          ImageURLs: [imageURL],
        },
      };
      const response = await deso.posts.submitPost(request);
      console.log(response);
      const postHashHexOfNFT =
        response.submittedTransactionResponse.PostEntryResponse.PostHashHex;
      console.log(postHashHexOfNFT);

      //wait for 4 seconds
      await new Promise((resolve) => setTimeout(resolve, 4000));
      //code to mint the nft
      const request2 = {
        UpdaterPublicKeyBase58Check: publicKeyOfUser,
        NFTPostHashHex: postHashHexOfNFT.toString(),
        NumCopies: 1,
        NFTRoyaltyToCreatorBasisPoints: 500,
        NFTRoyaltyToCoinBasisPoints: 0,
        MinBidAmountNanos: 2000000000,
        HasUnlockable: false,
        IsForSale: false,
        MinFeeRateNanosPerKB: 1000,
      };
      const response2 = await deso.nft.createNft(request2);
      await new Promise((resolve) => setTimeout(resolve, 4000));

      //code to transfer the nft
      const request3 = {
        SenderPublicKeyBase58Check: publicKeyOfUser,
        ReceiverPublicKeyBase58Check: requesterPublicKey,
        NFTPostHashHex: postHashHexOfNFT.toString(),
        SerialNumber: 1,
        MinFeeRateNanosPerKB: 1000,
      };
      const response3 = await deso.nft.transferNft(request3);
      alert("NFT MINTED SUCCESSFULLY");
      setIsMintingTwo(false);
      setIsTwoMinted(true);
    } catch (err) {
      alert(`Error minting NFT: ${err}`);
      setIsMintingTwo(false);

      //add reload later on
    }
  };

  return (
    <div>
      <div className={`gradient leading-relaxed tracking-wide flex flex-col`}>
        <nav id='header' className='w-full z-30 top-0  py-1 '>
          <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-2 lg:py-6'>
            <div className='flex items-center'>
              <Link
                to='/'
                className='no-underline flex hover:no-underline font-bold text-2xl lg:text-4xl'>
                <img src={logo} alt='logo' className='h-16 w-16 rounded-full' />
                <span className='mx-2 text-white'> Noun141 </span>
              </Link>
            </div>
            <div className='' id='nav-content'>
                  {isLogging ? (
                    <div className='mx-auto px-10'>
                      <Loader/>
                    </div>
                  ) : level4loggedPk !== "" ? (
                    <div className='mx-auto px-10 flex flex-col items-center'>
                      <button>
                        <img
                          className='h-12 w-12 rounded-full'
                          src={`https://node.deso.org/api/v0/get-single-profile-picture/${level4loggedPk}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                        />
                      </button>
                      {/*  add a click to log out drop down buttton*/}
                      <div className='flex flex-col items-center justify-center my-1'>
                        <button
                          className='bg-white text-black font-bold py-2 px-4 rounded-full hover:bg-slate-200'
                          onClick={() => {
                            handleDesoLogout();
                          }}>
                          Logout
                        </button>
                      </div>
                    </div>
                  ) : (
                    <button
                      onClick={handleDesoLogin}
                      target={"_blank"}
                      id='navAction'
                      className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-800 font-extrabold rounded mt-4 lg:mt-0 py-4 px-8 shadow '>
                      Login
                    </button>
                  )}
                </div>
          </div>
        </nav>
      </div>

        <div className='flex flex-wrap my-8 justify-center  sm:justify-around'>
          <div className='border p-3 my-2 rounded-md'>
            <div className='flex items-center justify-around'>
              <div>
                {firstImageURL ? (
                  <img
                    src={firstImageURL}
                    alt='image'
                    className='h-32 w-32 rounded-md'
                  />
                ) : (
                  <div className='flex min-h-32 min-w-32 bg-gray-400'></div>
                )}
              </div>
              <button
                className='gradient px-3 py-2 rounded-md shadow-lg text-white'
                onClick={handleImageUpload}>
                {isOneUploading ? "Uploading..." : "Upload Image"}
              </button>
            </div>

            <div className='flex items-center space-x-10 my-4'>
              <p>NFT Number:</p>
              <input
                type='number'
                value={nftNumberOne}
                onChange={handleNftNumberOneChange}
                id='nftNumberOne'
                className='border px-2 py-2 rounded-md'
                placeholder='eg. 0100'></input>
            </div>
            <div className='flex items-center space-x-10 my-4'>
              <p>Input Number:</p>
              <input
                id='inputNumberOne'
                type='number'
                value={inputNumberOne}
                onChange={handleInputNumberOnChange}
                className='border px-2 py-2 rounded-md'
                placeholder='eg. 080'></input>
            </div>

            <div className='flex items-center space-x-10 my-4'>
              <p>Mint Requester</p>
              <input
                type='text'
                onChange={mintRequesterOneChange}
                className='border px-2 py-2 rounded-md'
                placeholder='BC1Y....'></input>
            </div>
            <>
              {firstUserProfile ? (
                <div className='flex justify-end my-2 items-center space-x-2'>
                  <img
                    src={`https://node.deso.org/api/v0/get-single-profile-picture/${firstUserProfile.publicKey}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                    alt='logo'
                    className='h-12 w-12 rounded-full'
                  />
                  <p>{firstUserProfile.username}</p>
                </div>
              ) : null}
            </>

            <div className='flex items-center  my-4 flex-col'>
              <p>AI Input Promt:</p>
              <textarea
                id='aiInputPromptOne'
                value={promtOneValue}
                onChange={handlePromtOneChange}
                type='text'
                className='border px-2 py-2 rounded-md w-full my-3 min-h-24'
                placeholder='eg. 3D render of cow'
              />
            </div>

            <div className='flex justify-center my-2'>
              <button
                className='gradient px-6 py-2 rounded-md shadow-lg text-white'
                onClick={handleMintOne}>
                {isMintingOne ? "Minting..." : isOneMinted ? "Minted" : "Mint"}
              </button>
            </div>
          </div>

          {/* second mint */}

          <div className='border p-3 my-2 rounded-md'>
            <div className='flex items-center justify-around'>
              <div>
                {secondImageURL ? (
                  <img
                    src={secondImageURL}
                    alt='image'
                    className='h-32 w-32 rounded-md'
                  />
                ) : (
                  <div className='flex min-h-32 min-w-32 bg-gray-400'></div>
                )}
              </div>
              <button
                className='gradient px-3 py-2 rounded-md shadow-lg text-white'
                onClick={handleImageUpload2}>
                {isTwoUploading ? "Uploading..." : "Upload Image"}
              </button>
            </div>

            <div className='flex items-center space-x-10 my-4'>
              <p>NFT Number:</p>
              <input
                id='nftNumberTwo'
                type='number'
                value={nftNumberTwo}
                onChange={(e) => {
                  setNftNumberTwo(e.target.value);
                }}
                className='border px-2 py-2 rounded-md'
                placeholder='eg. 0100'></input>
            </div>
            <div className='flex items-center space-x-10 my-4'>
              <p>Input Number:</p>
              <input
                type='number'
                id='inputNumberTwo'
                value={inputNumberTwo}
                onChange={(e) => {
                  setInputNumberTwo(e.target.value);
                }}
                className='border px-2 py-2 rounded-md'
                placeholder='eg. 080'></input>
            </div>
            <div className='flex items-center space-x-10 my-4'>
              <p>Mint Requester</p>
              <input
                type='text'
                onChange={mintRequesterTwoChange}
                value={secondMintRequesterInput}
                className='border px-2 py-2 rounded-md'
                placeholder='BC1Y....'></input>
            </div>
            <>
              {secondUserProfile ? (
                <div className='flex justify-end my-2 items-center space-x-2'>
                  <img
                    src={`https://node.deso.org/api/v0/get-single-profile-picture/${secondUserProfile.publicKey}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                    alt='logo'
                    className='h-12 w-12 rounded-full'
                  />
                  <p>{secondUserProfile.username}</p>
                </div>
              ) : null}
            </>

            <div className='flex items-center  my-4 flex-col'>
              <p>AI Input Promt:</p>
              <textarea
                type='text'
                id='aiInputPromptTwo'
                value={promtTwoValue}
                onChange={(e) => {
                  setPromtTwoValue(e.target.value);
                }}
                className='border px-2 py-2 rounded-md w-full my-3 min-h-24'
                placeholder='eg. 3D render of cow'
              />
            </div>
            <div className='flex justify-center my-2'>
              <button
                className='gradient px-6 py-2 rounded-md shadow-lg text-white'
                onClick={handleMintTwo}>
                {isMintingTwo ? "Minting..." : isTwoMinted ? "Minted" : "Mint"}
              </button>
            </div>
          </div>
        </div>
     
    </div>
  );
}
