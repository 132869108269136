import { useParams } from "react-router-dom";
import React from "react";
import logo from "../assets/logo.svg";
import Deso from "deso-protocol";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import Loader from "./Loader";
const deso = new Deso();
function NFT() {
  const [loading, setLoading] = useState(true);
  const [isLogging, setIsLogging] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [loginResponse, setLoginResponse] = useState(null);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState(null);
  const [postInfo, setPostInfo] = useState(null);
  const [NftInfo, setNftInfo] = useState(null);
  const [inputData, setInputData] = useState();
  const [metadata, setMetadata] = useState(null);
  const params = useParams();
  const postHashHex = params.postHashHex;
  function getTaggedUser(bodyPost) {
    //replace '/n' with ' ' in bodyPost
    const body = bodyPost.replace(/\n/g, " ");
    console.log(body);
    const taggedUser = body.match(/@[a-zA-Z0-9]+/g);

    return taggedUser;
  }

  useEffect(() => {
    async function initData() {
      // wait for 3 seconds

      const loggedInPublicKey = localStorage.getItem("loggedInUser");
      console.log(loggedInPublicKey);
      if (loggedInPublicKey) {
        //const loggedInUser = await deso.getUser(loggedInPublicKey);
        setLoggedInUser(loggedInPublicKey.toString());
      }
      const request = {
        PostHashHex: postHashHex,
      };
      const response = await deso.posts.getSinglePost(request);
      setPostInfo(response.PostFound);
      const postBody = response.PostFound.Body.toString();
      //find sentence enclosed in "" in postBody
      const quotedText = postBody.match(/"([^"]*)"/g);
      setInputData(quotedText[0]);
      const nftNum = postBody.slice(9, 13);
      const inputNum = postBody.slice(20, 24);

      setMetadata({
        nftNum: nftNum,
        inputNum: inputNum,
      });
      console.log(nftNum);
      console.log(inputNum);
      const response2 = await deso.nft.getNftEntriesForPostHash(request);
      setNftInfo(response2.data.NFTEntryResponses[0]);
      console.log(response2.data);
      const ownerPublicKey =
        response2.data.NFTEntryResponses[0].OwnerPublicKeyBase58Check;
      console.log(ownerPublicKey);
      const request2 = {
        PublicKeyBase58Check: ownerPublicKey,
      };
      const userResponse = await deso.user.getSingleProfile(request2);
      setOwnerInfo({
        username: userResponse.Profile.Username,
        publicKey: ownerPublicKey,
      });

      setIsPageLoading(false);
    }

    initData();
  }, []);

  const handleLogin = async () => {
    const user = await deso.identity.login(3);
    console.log(user);
    setIsLogging(true);

    setLoginResponse(JSON.stringify(user, null, 2));
    setIsLogging(false);
  };

  const handleLogOut = async () => {
    if (isLoggingOut) {
      return;
    }
    setIsLoggingOut(true);
    //clear local storage

    localStorage.removeItem("loggedInUser");

    setIsLoggingOut(false);
    //reload page
    window.location.reload();
  };

  return (
    <div>
      <div className='container-sm  gradient'>
        <nav id='header' className='w-full z-30 top-0  py-1 lg:py-6'>
          <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-2 lg:py-6'>
            <div className='flex items-center'>
              <Link
                to='/'
                className='no-underline flex hover:no-underline font-bold text-2xl lg:text-4xl'>
                <img src={logo} alt='logo' className='h-16 w-16 rounded-full' />
                <span className='mx-2 my-2 text-white'> Noun141 </span>
              </Link>
            </div>

            <div className='' id='nav-content'>
              {isLogging ? (
                <div className='mx-auto px-10'>
                  <Loader />
                </div>
              ) : loggedInUser !== null ? (
                <div className='mx-auto px-10 flex flex-col items-center'>
                  <button>
                    <img
                      className='h-12 w-12 rounded-full'
                      src={`https://node.deso.org/api/v0/get-single-profile-picture/${loggedInUser}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                    />
                  </button>
                  {/*  add a click to log out drop down buttton*/}
                  <div className='flex flex-col items-center justify-center my-1'>
                    <button
                      className='bg-white text-black font-bold py-2 px-4 rounded-full hover:bg-slate-200'
                      onClick={() => {
                        handleLogOut();
                      }}>
                      Logout
                    </button>
                  </div>
                </div>
              ) : (
                <button
                  onClick={handleLogin}
                  target={"_blank"}
                  id='navAction'
                  className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-800 font-extrabold rounded mt-4 lg:mt-0 py-4 px-8 shadow '>
                  Login
                </button>
              )}
            </div>
          </div>
        </nav>
      </div>
      {isPageLoading ? (
        <Loader />
      ) : (
        <div className='container mx-auto px-4'>
          <div className='flex flex-col items-center justify-center my-10'>
            <div className='text-xl my-3'>
              <p>Noun141 #{metadata.nftNum}</p>
              <p>Input: {metadata.inputNum}</p>
            </div>
            <img
              src={postInfo.ImageURLs[0]}
              className='sm:w-3/5 rounded-lg shadow-lg'></img>
            <p className='my-10 text-2xl'>{inputData}</p>
            <div className='flex flex-col items-center '>
              <p className='mt-3 text-lg'>
                Minted by{" "}
                <span>
                  <a
                    className='underline text-blue-400'
                    href={`https://diamondapp.com/u/${getTaggedUser(
                      postInfo.Body
                    )
                      .toString()
                      .slice(1)}`}>
                    {getTaggedUser(postInfo.Body)}
                  </a>
                </span>
              </p>
              <div className='my-2 text-lg flex flex-row items-center'>
                Owned by{"  "}
                <span>
                  <a
                    className='underline mx-2 text-blue-400 flex flex-row items-center'
                    href={`https://diamondapp.com/u/${ownerInfo.username}`}>
                    <p>{ownerInfo.username}</p>
                    <img
                      src={`https://node.deso.org/api/v0/get-single-profile-picture/${ownerInfo.publicKey}?fallback=https://diamondapp.com/assets/img/default_profile_pic.png`}
                      className='h-12 w-12 mx-1 rounded-full'></img>
                  </a>
                </span>
              </div>
            </div>

            <div className="flex flex-col items-center">
                {NftInfo.IsForSale?(
                    <div className='flex flex-row items-center justify-center my-10'>
                        <a
                        href = {`https://diamondapp.com/nft/${postHashHex}`}
                        target={"_blank"}
                        className='mx-auto gradient2 lg:mx-0 hover:underline text-gray-800 font-extrabold rounded mt-4 lg:mt-0 py-4 px-8 shadow '>
                            Buy NFT on Diamond 
                        </a>
                    </div>
                ):(
                    <div className='flex flex-row items-center justify-center my-10 gradient2 px-3 py-1 rounded-md'>
                        Not For Sale 
                    </div>
                )}

            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NFT;
